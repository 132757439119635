<template>
  <b-card>
    <baseform
      :fields="fields"
      :posturl="dataurl"
      :title="title"
    />
  </b-card>
</template>

<script>
import baseform from '@/views/base/BaseForm.vue'
import {BCard} from 'bootstrap-vue'

export default {
  components: {
    baseform,
    BCard,
  },
  data() {
    return {
      dataurl:"/vehicle-manufacture",
      baseroute:"vehicle-manufacture",
      title:"Pabrikan",
      fields : [
        { key: 'name', label: 'Nama Pabrikan', type: 'input', rules:'required' },
        { key: 'product', label: 'Produk', type: 'input', rules:'required' },
        // { key: 'type', label: 'Tipe', type: 'select-ajax', rules:'required', dataurl:'/vehicle-type', title:'Tipe Kendaraan' }
      ]
    }
  },
}
</script>